;(function () {
    'use strict';

    var fileFields = document.querySelectorAll('.js-filefield');

    Array.prototype.forEach.call(fileFields, function(fileField, i) {

        var fileFieldInput = fileField.querySelector('input[type="file"]');
        if (typeof fileFieldInput !== 'undefined' && fileFieldInput !== null) {

            var fileFieldButton = fileField.querySelector('.js-filefield-button');
            if (typeof fileFieldButton !== 'undefined' && fileFieldButton !== null) {

                var fileFieldValue = fileField.querySelector('.js-filefield-value');
                if (typeof fileFieldValue !== 'undefined' && fileFieldValue !== null) {

                    fileFieldInput.addEventListener('change', function(e) {

                        var fileName = '';
                        var buttonHasFile = fileFieldButton.classList.contains('-has-file');

                        if (this.files && this.files.length > 0) {
                            fileName = e.target.value.split('\\').pop();
                            fileFieldValue.innerHTML = fileName;
                            if (!buttonHasFile) {
                                fileFieldButton.classList.add('-has-file');
                            }
                        }
                        else if (buttonHasFile) {
                            fileFieldButton.classList.remove('-has-file');
                        }
                    });
                }
            }
        }
    });

    // ready(function() {
    //     setTimeout(function() {
    //         var hash = location.hash;
    //         if (hash.length) {
    //             smoothScrollToHashTarget(hash);
    //             return false;
    //         }
    //     }, 1);
    // });

}());
