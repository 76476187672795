;(function (d) {
    'use strict';

    var htmlEl = d.querySelector('html');

    d.addEventListener('mousedown', function() {
        if (htmlEl.classList.contains('is-keys')) {
            htmlEl.classList.remove('is-keys');
        }
    })

    d.addEventListener('keydown', function() {
        if (!htmlEl.classList.contains('is-keys')) {
            htmlEl.classList.add('is-keys');
        }
    })

})(document);
