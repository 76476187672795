;(function () {
    'use strict';

    var modalInits = document.querySelectorAll('.js-modal-init, [data-modal-init]');
    if (modalInits.length > 0) {

        Array.prototype.forEach.call(modalInits, function(modalInit, i) {

            var modalSelector = modalInit.getAttribute('data-modal-target-id');
            if (typeof modalSelector !== 'undefined' && modalSelector !== null) {

                var modalElement = document.getElementById(modalSelector);
                if (typeof modalElement !== 'undefined' && modalElement !== null) {

                    onClickOrTap(modalInit, function(e) {
                        e.preventDefault();
                        var modalSelector = modalInit.getAttribute('data-modal-target-id');
                        var modalElement = document.getElementById(modalSelector);
                        var modalCheckOpenCallback = modalElement.getAttribute('data-modal-check-open-callback');
                        var modalOpenCallback = modalElement.getAttribute('data-modal-open-callback');
                        var modalCloseCallback = modalElement.getAttribute('data-modal-close-callback');
                        var resetContent = modalElement.getAttribute('data-modal-reset');
                        var modal = new A11yDialog(modalElement, 'body');


                        if (modalCheckOpenCallback) {
                            if (window[modalCheckOpenCallback]()) {
                                modal.show();
                            }
                            else {
                                return;
                            }
                        }
                        else {
                            modal.show();
                        }

                        if (typeof modalOpenCallback !== 'undefined' && modalOpenCallback !== null) {
                            window[modalOpenCallback].call(modalElement);
                        }

                        var body = document.querySelector('body');
                        if (body.classList && !body.classList.contains('has-modal-open')) {
                            body.classList.add('has-modal-open');
                        }

                        if (typeof resetContent !== 'undefined' && resetContent !== null) {

                            var modalTitle = modalInit.getAttribute('data-modal-title');
                            if (typeof modalTitle !== 'undefined' && modalTitle !== null) {
                                modalElement.querySelector('.js-modal-title').innerText = modalTitle;
                            }

                            var modalContentSelector = modalInit.getAttribute('data-modal-content');
                            if (typeof modalContentSelector !== 'undefined' && modalContentSelector !== null) {
                                var modalContentElement = document.querySelector(modalContentSelector);
                                if (typeof modalContentElement !== 'undefined' && modalContentElement !== null) {
                                    modalElement.querySelector('.js-modal-content').innerHTML = modalContentElement.outerHTML;
                                }
                            }

                            modal.on('hide', function(dialogEl, event) {
                                dialogEl.querySelector('.js-modal-title').innerText = '';
                                dialogEl.querySelector('.js-modal-content').innerHTML = '';
                                var body = document.querySelector('body');
                                if (body.classList && body.classList.contains('has-modal-open')) {
                                    body.classList.remove('has-modal-open');
                                }
                                if (typeof modalCloseCallback !== 'undefined' && modalCloseCallback !== null) {
                                    window[modalCloseCallback].call(dialogEl);
                                }
                            });
                        }
                        else {
                            modal.on('hide', function(dialogEl, event) {
                                var body = document.querySelector('body');
                                if (body.classList && body.classList.contains('has-modal-open')) {
                                    body.classList.remove('has-modal-open');
                                }
                                if (typeof modalCloseCallback !== 'undefined' && modalCloseCallback !== null) {
                                    window[modalCloseCallback].call(dialogEl);
                                }
                            });
                        }
                    });
                }
            }
        });
    }
}());
