;(function () {
    'use strict';

    var doSectionNavToggle = function (button) {
        var isExpanded = button.getAttribute('aria-expanded') === 'true';
        var sectionNav = getClosest(button, '.js-sectionnav');
        if (sectionNav)
        {
            if (isExpanded) {
                sectionNav.classList.remove('is-open');
                button.setAttribute('aria-expanded', 'false');
            }
            else {
                sectionNav.classList.add('is-open');
                button.setAttribute('aria-expanded', 'true');
            }

        }
    }

    var toggles = document.querySelectorAll('.js-sectionnav-toggle');
    Array.prototype.forEach.call(toggles, function(toggle, i)
    {
        onClickOrTap(toggle, function(e) {
            e.preventDefault();
            doSectionNavToggle(toggle);
        });
    });
}());
