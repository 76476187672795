;(function () {
    'use strict';

    var toggles = document.querySelectorAll('.js-toggle');
    Array.prototype.forEach.call(toggles, function(toggle, i)
    {
        var toggleTrigger = toggle.querySelector('.js-toggle-trigger');
        var toggleBody = toggle.querySelector('.js-toggle-body');
        if (
            typeof toggleTrigger !== 'undefined' && toggleTrigger !== null
            && typeof toggleBody !== 'undefined' && toggleBody !== null
        ) {
            onClickOrTap(toggleTrigger, function(e)
            {
                e.preventDefault();

                if (!toggle.classList) {
                    return false;
                }

                var currIsActive = toggle.classList.contains('is-active');
                if (!currIsActive)
                {
                    var activeTogglesInGroup = false;
                    var toggleGroupName = toggle.getAttribute('data-js-toggle-group');
                    if (typeof toggleGroupName !== 'undefined' && toggleGroupName !== null) {
                        activeTogglesInGroup = document.querySelectorAll(
                            '.js-toggle.is-active[data-js-toggle-group="' + toggleGroupName + '"]'
                        );
                    }
                    if (activeTogglesInGroup && activeTogglesInGroup.length > 0) {
                        Array.prototype.forEach.call(activeTogglesInGroup, function(activeToggle, i) {
                            activeToggle.classList.remove('is-active');
                            var activeToggleBody = activeToggle.querySelector('.js-toggle-body');
                            activeToggleBody.classList.remove('is-active');
                        });
                    }
                    toggle.classList.add('is-active');
                    toggleBody.classList.add('is-active');
                }
                else {
                    toggle.classList.remove('is-active');
                    toggleBody.classList.remove('is-active');
                }
                return false;
            });
        }
    });
}());
