;(function () {
    'use strict';

    var tabbed_switchTab = function (oldTab, newTab) {
        newTab.focus();
        var tabbed = getClosest(newTab, '.js-tabbed');
        var tablist = tabbed.querySelector('.js-tabbed-tabs');
        var tabs = tablist.querySelectorAll('.js-tabbed-tab');
        var panels = tabbed.querySelectorAll('.js-tabbed-panel');
        // Make the active tab focusable by the user (Tab key)
        newTab.removeAttribute('tabindex');
        newTab.parentNode.classList.add('is-selected');
        // Set the selected state
        newTab.setAttribute('aria-selected', 'true');

        if (typeof(oldTab) !== 'undefined' && oldTab !== null)
        {
            oldTab.removeAttribute('aria-selected');
            oldTab.setAttribute('tabindex', '-1');
            oldTab.parentNode.classList.remove('is-selected');

            var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
            panels[oldIndex].classList.remove('is-active');
            panels[oldIndex].hidden = true;
        }

        // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide
        var index = Array.prototype.indexOf.call(tabs, newTab);
        panels[index].classList.add('is-active');
        panels[index].hidden = false;

        history.pushState({},'', newTab.getAttribute('href'));
    };

    var tabbed_tabClickHandler = function (e) {
        e.preventDefault();
        var tabbed = getClosest(e.currentTarget, '.js-tabbed');
        var tablist = tabbed.querySelector('.js-tabbed-tabs');
        var currentTab = tablist.querySelector('[aria-selected]');
        if (e.currentTarget !== currentTab) {
            tabbed_switchTab(currentTab, e.currentTarget);
        }
    };

    var tabbed_tabClickKeydownHandler = function (e) {
        var tabbed = getClosest(e.currentTarget, '.js-tabbed');
        var tablist = tabbed.querySelector('.js-tabbed-tabs');
        var tabs = tablist.querySelectorAll('.js-tabbed-tab');
        var panels = tabbed.querySelectorAll('.js-tabbed-panel');
        // Get the index of the current tab in the tabs node list
        var index = Array.prototype.indexOf.call(tabs, e.currentTarget);
        // Work out which key the user is pressing and
        // Calculate the new tab's index where appropriate
        var dir = e.which === 37 ? index - 1 : e.which === 39 ? index + 1 : e.which === 40 ? 'down' : null;
        if (dir !== null) {
            e.preventDefault();
            // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab
            dir === 'down' ? panels[index].focus() : tabs[dir] ? tabbed_switchTab(e.currentTarget, tabs[dir]) : void 0;
        }
    };

    var tabbed_doInit = function()
    {
        var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var tabCollections = width < 768
            ? document.querySelectorAll('.js-tabbed.is-tabbed')
            : document.querySelectorAll('.js-tabbed:not(.is-tabbed)');

        if (tabCollections.length < 1) return;
        Array.prototype.forEach.call(tabCollections, function (tabbed)
        {
            var isTabbed = tabbed.classList.contains('is-tabbed');
            var tablist = tabbed.querySelector('.js-tabbed-tabs');
            var tabs = tablist.querySelectorAll('.js-tabbed-tab');
            var panels = tabbed.querySelectorAll('.js-tabbed-panel');

            if (width >= 768 && !isTabbed)
            {
                // Semantics: add the tablist role to the tabs container
                tablist.setAttribute('role', 'tablist');

                var currPath = window.location.pathname + window.location.hash;
                var activateIndex = 0;

                Array.prototype.forEach.call(tabs, function (tab, i)
                {
                    // Semantics
                    tab.setAttribute('role', 'tab');
                    tab.setAttribute('id', 'tab' + (i + 1));
                    tab.setAttribute('tabindex', '-1');
                    tab.parentNode.setAttribute('role', 'presentation');

                    // Reset selection
                    tab.removeAttribute('aria-selected');
                    tab.parentNode.classList.remove('is-selected');

                    // Handle clicking of tabs for mouse/touch users
                    tab.addEventListener('click', tabbed_tabClickHandler, false);

                    // Handle keydown events for keyboard users
                    tab.addEventListener('keydown', tabbed_tabClickKeydownHandler, false);

                    var tabHref = tab.getAttribute('href');
                    if (currPath && currPath === tabHref) {
                        activateIndex = i;
                    }
                });

                Array.prototype.forEach.call(panels, function (panel, i)
                {
                    // Semantics
                    panel.setAttribute('role', 'tabpanel');
                    panel.setAttribute('tabindex', '-1');
                    panel.setAttribute('aria-labelledby', tabs[i].id);

                    // Reset selection
                    panel.classList.remove('is-active');
                    panel.hidden = true;
                });


                // Activate first tab and reveal first tab panel
                tabs[activateIndex].removeAttribute('tabindex');
                tabs[activateIndex].setAttribute('aria-selected', 'true');
                tabs[activateIndex].parentNode.classList.add('is-selected');
                panels[activateIndex].classList.add('is-active');
                panels[activateIndex].hidden = false;

                // Mark tabbed component as initialised
                tabbed.classList.add('is-tabbed');
            }
            else if (width < 768 && isTabbed)
            {
                // Semantics: remove the tablist role from the tabs container
                tablist.removeAttribute('role');

                Array.prototype.forEach.call(tabs, function (tab, i)
                {
                    // Remove tab semantics
                    tab.removeAttribute('role');
                    tab.removeAttribute('id');
                    tab.removeAttribute('tabindex');

                    // Remove selection
                    tab.removeAttribute('aria-selected');
                    tab.parentNode.classList.remove('is-selected');

                    // Remove click/keydown handlers
                    tab.removeEventListener('click', tabbed_tabClickHandler, false);
                    tab.removeEventListener('keydown', tabbed_tabClickKeydownHandler, false);
                });

                Array.prototype.forEach.call(panels, function (panel, i)
                {
                    // Remove panel semantics
                    panel.removeAttribute('role');
                    panel.removeAttribute('tabindex');
                    panel.removeAttribute('aria-labelledby');

                    // Remove selection
                    panel.classList.remove('is-active');
                    panel.hidden = false;
                });

                // Mark tabbed component as destroyed (by removing init'd class)
                tabbed.classList.remove('is-tabbed');
            }
        });
    };

    window.addEventListener('resize', throttle(tabbed_doInit, 200), false);
    tabbed_doInit();
}());
