;(function () {
    'use strict';

    var autocompleteTextFieldInit = function(field, fieldData)
    {
        var jsHook = '.' + fieldData.js;
        var cssHook = fieldData.css;
        var dataHook = fieldData.data;
        var choicesString = field.getAttribute(dataHook + '-choices');
        var choices = JSON.parse(choicesString);
        var input = field.querySelector('input' + jsHook + '-input');
        var resultsHolder = field.querySelector(jsHook + '-results');
        var fuseConfigString = field.getAttribute(dataHook + '-fuse');
        var fuseConfig = false;
        if (typeof fuseConfigString !== 'undefined' && fuseConfigString !== null) {
            fuseConfig = JSON.parse(fuseConfigString);
        }

        autocomplete({
            input: input,
            positioning: false,
            fetch: function(text, update) {
                var results = [];
                if (fuseConfig) {
                    var fuse = new Fuse(choices, fuseConfig);
                    results = fuse.search(text);
                }
                else {
                    text = text.toLowerCase();
                    results = choices.filter(function(n) {
                        return n.title.toLowerCase().includes(text);
                    });
                }
                update(results);
            },
            onSelect: function(item) {
                input.value = item.item.title
            },
            minLength: 2,
            render: function(item, currentValue) {
                var link = document.createElement('div');
                link.classList.add(cssHook + '__result');
                link.textContent = item.item.title;
                return link;
            },
            className: cssHook + '__results',
            preventSubmit: true,
            customize: function(input, inputRect, container, maxHeight) {
                container.classList.remove('autocomplete');
                resultsHolder.append(container);
            }
        });
    };

    var fields = window.autocompleteTextFields;
    if (typeof fields !== 'undefined' && fields !== null) {
        Array.prototype.forEach.call(fields, function(fieldData, i) {
            autocompleteTextFieldInit(fieldData.element, fieldData);
        });
    }
}());
