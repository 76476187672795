;(function () {
    'use strict';

    var menuToggleLinks = document.querySelectorAll('.js-modalmenu-link');

    Array.prototype.forEach.call(menuToggleLinks, function(toggleLink, i) {

        var toggleItem = getClosest(toggleLink, '.js-modalmenu-item');
        if (typeof toggleItem !== 'undefined' && toggleItem !== null)
        {
            onClickOrTap(toggleLink, function(e) {
                e.preventDefault();
                if (toggleItem.classList) {
                    toggleItem.classList.toggle('is-open');
                }
                return false;
            });
        }
    });
}());
